export default {
  name: 'home',
  namespaced: true,
  state() {
    return {
      themeColor: {
        header_background_color: '',
        header_text_color: '',
        menu_background_color: '',
        menu_text_color: '',
        copy_background_color: '',
        content_background_color: '',
        content_table_color: '',
        content_border_color: '',
        content_text_color: ''
      },
      iconType: false
    }
  },
  mutations: {
    setThemeColor(state, obj) {
      state.themeColor = obj;
    },
    setIconType(state, data) {
      state.iconType = data;
    }
  }
}
