import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale';
import en from './langs/en'
import enLocale from 'element-ui/lib/locale/lang/en'

Vue.use(VueI18n)

const messages = {
    en: Object.assign(en, enLocale),
}

// 设置英文
sessionStorage.setItem('locale', en)
const i18n = new VueI18n({
    locale: 'en',
    messages
})


locale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换

export default i18n
