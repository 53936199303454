export const hostUrl = function() {
  var port = location.port;
  var hostUrl = "http://";

  if (port == 80) {
    hostUrl += location.hostname;
  } else {
    hostUrl += location.host;
  }

  return hostUrl;
}()

export function themeColor(url) {
  let colors = '';
  let data = {
    orange: {
      header_background_color: '#ED6D01',
      header_text_color: '#fff',
      menu_background_color: '#24262F',
      menu_text_color: '#fff',
      copy_background_color: '#000',
      copy_text_color: '#fff',
      content_background_color: '#eee',
      content_table_color: '#FFEBCD',
      content_border_color: '#E60130',
      content_text_color: '#333'
    }
  }
  switch (url) {
    default: colors = 'orange';
      break;
  }
  return data[colors];
}

export function getTimezone() {
  let timeString = (new Date()).toString().split("GMT")[1].split(" ")[0]
  return encodeURIComponent([timeString.slice(0, 3), ":", timeString.slice(3)].join(''))
}

export function getTimezoneName() {
  return new Date().toLocaleDateString(undefined, {day:'2-digit',timeZoneName: 'short' }).substring(4)
}

