export default {
    login: {
        "EMAIL": "Email",
        "USERNAME": "Username",
        "PASSWORD": "Password",
        "SUBMIT": "Login",
        "RESET": "Reset",
        "ERROR_EMPTY_PASSWORD": "Password cannot be empty",
        "INFO_LOGIN_SUCCESS": "Login Success",
        "LOGIN_INCORRECT": "Invalid username or password",
        "A02": "Wrong Password",
        "SWITCHING_CLIENT": "Switching client, please wait ...",
        "MFA_CODE": "MFA Code",
        "MFA_REQUIRED": "MFA Code will be sent to your mobile phone over SMS",
        "MFA_INVALID": "Invalid MFA Code, please double confim and submit again"
    },
    header: {
        "CLICK_ON": "Click on",
        "CLICK_ON_THE_FOLD": "Click on the fold"
    },
    menu: {
        "ORDER_LIST": "Order List",
        "ORDER_MANAGEMENT": "Order Management",
        "CREATE_ORDER": "Create Order",
        "C_A_P": "Channels and Products",
        "C_M": "Channel Management",
        "P_M": "Product Management",
        "PACKAGE_MANAGE": "Package Tracking",
        "STATE_M": "State Management ",
        "BASIC_INFO": "Basic Information",
        "ERROR_STATE": "Error State",
        "VENDORS_CONFIG": "供应商配置",
        "VENDORS_MANAGEMENT": "供应商管理",
        "USER_MANAGE": "User Management",
        "ROLE_MANAGE": "Role Management",
        "CONFIG_MANAGE": "Config Center",
        "ORDER_MANAGE": "Order Management",
        "ORDER_MANAGE_BREXIT": "Order Management(Brexit)",
        "ORDER_MANAGE_BILLING":"Billing",
        "MANIFEST_SEARCH": "Manifest Search",
        "PACKAGE_LIST": "package List",
        "ORDER_JOB": "Job",
        "ORDER_MANIFEST": "Manifest",
        "ORDER_PACKAGE": "Package",
        "ORDER_ALL": "All",
        "ORDER_ALL_TRANSIT": "In Transit",
        "ORDER_ALL_WIP": "WIP",
        "ORDER_ALL_DELIVERY":"Delivery in Progress",
        "ORDER_ALL_DELIVERED":"Delivered",
        "ORDER_ALL_EXCEPTION":"Exception",
        "ORDER_INVOICE": "Invoice",
        "ORDER_PREALERT":"Package Pre Alert",
        // "VIEW_ORDER_DELIVERED": "Delivered",
        // "VIEW_ORDER_EXCEPTION": "Exception",
        "ORDER_TRAILER":"Trailer",
        "ORDER_TRAILER_GROUND_DASHBOARD": "Dashboard - AGL Ground",
        "ORDER_TRAILER_AIR_DASHBOARD": "Dashboard - AGL Air",
        "ORDER_TRAILER_CANARY_DASHBOARD": "Dashboard - Canary",
        "ORDER_STOCK_TRANSFER_DASHBOARD": "Dashboard - Stock Transfer",
        "ORDER_STOCK_TRANSFER_EXPORT_DECLARATION_UPLOAD": "Export Declaration",
        "ORDER_STOCK_TRANSFER_ASSIST_UPLIFT_UPLOAD": "Assist Uplift Upload",
        "CACESA_POE_UPLOAD":"Cacesa POE Upload",
        "INVOICE_RULE": "Invoice Rules",
        "STATISTICAL": "Statistical",
        "ORDER_PACKAGE_BREXIT":"Package",
        "BILLING_CONFIG":"Billing Config",
        "BILLING_AMZ_INVOICE":"AMZ Invoice",
        "BILLING_INVOICE_AMZ":"Invoice List",
        "BILLING_INVOICE_CONFIRM": "Confirm AGL Invoice",
        "CREATE_CREDIT_NOTE_AGL": "Create Credit Note (AGL/NLTR)",
        "CREATE_CREDIT_NOTE_GTS": "Create Credit Note (GTS)",
        "CREATE_NLCN_INVOICE": "Confirm NLCN Invoice",
        "EDI_FILE_UPLOAD": "EDI File Upload",
        "EU_Y_C_CODE_UPLOAD": "EU Additional Code Upload",
        "EDI_ARCHIVE": "AGL Data Archive",
        "DEVOPS_INTERNAL_API": "Internal API",
        "DATA_REQUEST": "Data Request",
        "DATA_REQUEST_HS_CODE": "HS Code",
        "DATA_REQUEST_STATUS_LIST": "Status List",
        "DATA_REQUEST_CLEARANCE_STATUS": "Clearance Status",
        "DATA_REQUEST_EDI_RECEIVE_LOG": "EDI Receive Log",
        "DATA_REQUEST_MISSING_DOCS": "Missing Docs",
        "DATA_REQUEST_PALLET_RELATION": "Pallet Relation",
        "DATA_REQUEST_ES_KYC": "ES KYC",
        "DATA_REQUEST_NLTR_MANIFEST": "NLTR Manifest",
        "DATA_REQUEST_INVOICE_LIST": "Invoice/SAD",
        "DATA_REQUEST_INTERNAL_INVOICE_LIST": "Internal Invoice",
        "DATA_REQUEST_GTS_EXPORT_DECLARATION": "Export Declaration",
        "DATA_REQUEST_GTS_ASSIST_UPLIFT_REPORT": "Assist Uplift Report",
        "DATA_REQUEST_GTS_STATUS": "Status List",
        "DATA_REQUEST_GTS_CUS_INVOICE": "Invoice CUS List",
        "DATA_REQUEST_GTS_DTY_INVOICE": "Invoice DTY List",
        "TURKEY_NETHERLANDS": "Turkey-Netherlands",
        "TURKEY_NETHERLANDS_ORIGIN": "Origin Scan",
        "TURKEY_NETHERLANDS_DESTINATION": "Destination Scan",
        "TURKEY_NETHERLANDS_DASHBOARD_LOADID": "Dashboard (LoadID)",
        "TURKEY_NETHERLANDS_DASHBOARD_MAWB_NORMAL": "Dashboard (Normal)",
        "TURKEY_NETHERLANDS_DASHBOARD_MAWB_RETURN": "Dashboard (Return)",
        "TURKEY_NETHERLANDS_PRE_MANIFEST": "Pre-Manifest",
        "TURKEY_NETHERLANDS_POA_REVIEW": "POA Review",
        "TURKEY_NETHERLANDS_STATUS": "Status / Exception",
        "TURKEY_NETHERLANDS_HSCODE_OVERRIDE": "HSCode Override",
        "TURKEY_NETHERLANDS_TAX_AND_DUTY_UPLOAD": "Tax & Duty Upload",
        "GTS": "Stock Transfer",
        "CANARY": "Canary Islands",
        "CANARY_STATUS": "Status / Exception",
        "DATA_REQUEST_GTS": "Data Request (GTS)",
        "DATA_REQUEST_AGL_NLTR": "Data Request (AGL/NLTR/Canary)",
        "GATEWAY_STATUS_DASHBOARD": "Dashboard - NLTR Return"
    },
    order: {
        "NUMDER": 'Manifest NO',
        "CUSTOMER": 'Customer',
        "SHIPMENT_DATE": 'Shipment Date',
    },
    // billing

}
